import {
  BUILD_CAPTIVE_URL,
  POWERED_BY,
  SITE_TITLE,
} from '@conkoa/core/constants'
import { Button } from '@conkoa/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from '@conkoa/ui/dropdown-menu'
import { cn } from '@conkoa/ui/utils/misc'
import { Form, Link, useSubmit } from '@remix-run/react'
import {
  ArrowRightIcon,
  LogOutIcon,
  SparklesIcon,
  UserCircleIcon,
} from 'lucide-react'
import { useRef } from 'react'
import { ClientOnly } from 'remix-utils/client-only'
import ConstructionBuddyIcon from '~/components/icons/construction-buddy.svg?react'
import { ThemeSwitch, useTheme } from '~/routes/api.theme-change'
import { useOptionalUser, useUser } from '~/utils/user'

export function SiteHeader({
  noBorder,
  hideIcon,
}: {
  noBorder?: boolean
  hideIcon?: boolean
}) {
  const user = useOptionalUser()
  return (
    <div
      className={cn(
        'w-full border-b border-muted bg-background',
        noBorder && 'border-none',
      )}
    >
      <header className="px-2 sm:px-4 lg:px-6 xl:px-8">
        <nav className="flex flex-nowrap items-center justify-between py-3">
          <div>
            <Link
              to="/"
              className={cn(
                'inline-flex items-center sm:gap-4',
                hideIcon && 'hidden',
              )}
            >
              <ConstructionBuddyIcon className="h-10 w-10 lg:h-12 lg:w-12 xl:h-14 xl:w-14" />
              <span className="hidden text-center text-lg font-medium text-foreground sm:inline">
                {SITE_TITLE}
              </span>
            </Link>
          </div>
          <div className="z-10">
            <ClientOnly
              fallback={
                <div className="flex items-center gap-2 sm:gap-3">
                  <Button
                    asChild
                    variant="link"
                    size="lg"
                    className="px-6 text-foreground hover:underline focus:underline"
                  >
                    <Link to="/login">Login</Link>
                  </Button>
                  <SignupButton />
                </div>
              }
            >
              {() =>
                user ? (
                  <UserDropdown />
                ) : (
                  <div className="flex items-center gap-2 sm:gap-3">
                    <Button
                      asChild
                      variant="link"
                      size="lg"
                      className="px-6 text-foreground hover:underline focus:underline"
                    >
                      <Link to="/login">Login</Link>
                    </Button>
                    <SignupButton />
                  </div>
                )
              }
            </ClientOnly>
          </div>
        </nav>
      </header>
    </div>
  )
}

export function SignupButton() {
  return (
    <Link
      to="/signup"
      className="inline-flex flex-col rounded-md border-2 border-muted-foreground/50 p-4 transition-colors hover:border-secondary/50 hover:bg-card focus:border-secondary/50 focus:bg-card"
    >
      <div className="flex items-center justify-center gap-3 sm:gap-6">
        <span className="whitespace-nowrap text-center">Sign-up</span>
        <span className="flex items-center justify-center rounded-md bg-secondary p-1.5">
          <ArrowRightIcon className="h-6 w-6 text-secondary-foreground" />
        </span>
      </div>
    </Link>
  )
}

const footerNav = [
  // {
  //   name: 'About',
  //   href: '/about',
  // },
  {
    name: 'Contact Us',
    href: BUILD_CAPTIVE_URL,
  },
  {
    name: 'Privacy Policy',
    href: '/privacy-policy',
  },
  {
    name: 'Terms of Service',
    href: '/terms-of-service',
  },
  {
    name: 'Pricing',
    href: '/pricing',
  },
]

export function SiteFooter() {
  const theme = useTheme()

  return (
    <footer className="border-t border-muted bg-background brightness-95 filter dark:border-muted dark:bg-card dark:brightness-100">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-5 lg:py-4">
        <Link
          to="/"
          className="block w-full text-center text-sm font-bold lg:hidden"
        >
          {SITE_TITLE}
        </Link>
        <nav
          className="-mb-6 mt-3 columns-2 sm:flex sm:justify-center sm:space-x-12"
          aria-label="Footer"
        >
          <div className="hidden lg:block">
            <Link to="/" className="text-sm font-bold">
              {SITE_TITLE}
            </Link>
          </div>
          {footerNav.map((item) => (
            <div key={item.name} className="pb-4">
              <Link
                to={item.href}
                className="text-sm leading-6 text-muted-foreground hover:text-foreground"
              >
                {item.name}
              </Link>
            </div>
          ))}
          <div className="hidden lg:block">
            <ThemeSwitch id="desktop" userPreference={theme} />
          </div>
        </nav>
        <div className="mt-6 flex w-full flex-col items-center justify-center gap-4 sm:flex-row lg:hidden">
          <ThemeSwitch id="mobile" userPreference={theme} />
        </div>
        <p className="mt-2 text-center text-xs leading-5 text-gray-500 sm:mt-3 lg:mt-4">
          &copy; {new Date().getFullYear()} {POWERED_BY} All rights reserved.
        </p>
      </div>
    </footer>
  )
}

export function UserDropdown() {
  const user = useUser()
  const submit = useSubmit()
  const formRef = useRef<HTMLFormElement>(null)

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Link
          to={'/settings/profile'}
          // this is for progressive enhancement
          onClick={(e) => e.preventDefault()}
          className="inline-flex w-full flex-row items-center gap-3 rounded-md border-2 border-muted-foreground/50 p-2.5 px-4 transition-colors hover:border-secondary/50 hover:bg-card focus:border-secondary/50 focus:bg-card"
        >
          {user.image?.url ? (
            <img
              className="h-7 w-7 rounded-md object-cover"
              alt={user.name ?? user.email}
              src={user.image.url}
            />
          ) : null}
          <span className="text-sm font-bold">{user.name}</span>
        </Link>
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent sideOffset={8} align="start">
          {user.isAdmin || user.isSubscriptionActive ? (
            <>
              <DropdownMenuItem asChild>
                <Link
                  prefetch="intent"
                  to="/chat"
                  className="flex items-center gap-2"
                >
                  <SparklesIcon className="h-4 w-4" />
                  AI Chat
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem asChild>
                <Link
                  prefetch="intent"
                  to="/generate-form"
                  className="flex items-center gap-2"
                >
                  <SparklesIcon className="h-4 w-4" />
                  Generate Form
                </Link>
              </DropdownMenuItem>
            </>
          ) : null}
          <DropdownMenuItem asChild>
            <Link
              prefetch="intent"
              to="/settings/profile"
              className="flex items-center gap-2"
            >
              <UserCircleIcon className="h-4 w-4" />
              Profile
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem
            asChild
            // this prevents the menu from closing before the form submission is completed
            onSelect={(event) => {
              event.preventDefault()
              submit(formRef.current)
            }}
          >
            <Form action="/logout" method="POST" ref={formRef}>
              <button type="submit" className="flex items-center gap-2">
                <LogOutIcon className="h-4 w-4" />
                <span>Logout</span>
              </button>
            </Form>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  )
}
